<template>
  <div id="cspio-page">
    <div id="cspio-content" style="display: block;" class="animated fadeIn">
      <h1 id="cspio-headline">השטיח המעופף </h1>
      <div id="cspio-description" style="direction:rtl">האתר בתחזוקה עולים עוד מעט בחזרה!</div>

      <form id="cspio-form" action="" method="post">
        <div id="cspio-field-wrapper">
          <div class="row">

          </div>
        </div>
      </form>

      <span id="cspio-privacy-policy-txt" style="direction:rtl">מתנצלים על ההמתנה.</span>

      <div id="cspio-socialprofiles">
        <a href="mailto:" target="_blank"><i class="fa fa-envelope fa-2x"></i></a>
      </div>

    </div><!-- end of #cspio-content -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { BJumbotron } from 'bootstrap-vue';

export default {
  components: {
    // BJumbotron,
    // BButton,
  },
  data() {
    return {
      noAgency: false,
      isMaintenance: true,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
  },
  methods: {
    gotoMainDomain() {
      window.location.href = process.env.VUE_APP_MAIN_DOMAIN;
      // window.location.href = 'https://flying-carpet.vercel.app/';
    },
  },
};
</script>

<style>
  html {
    height: 100%;
    overflow: hidden;
  }

  body {
    height:100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background: transparent;
    text-align: center;
  }

  html {
    height:100%;
    background: url("https://images.unsplash.com/photo-1449168013943-3a15804bb41c?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&w=1080&fit=max&s=1958d4bfb59a246c6092ff0daabd284b") no-repeat center bottom fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #cspio-page{
    background-color: rgba(0,0,0,0);
    min-height: 100%;
    display: flex;
  }

  #cspio-page{
    background: -moz-radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.2) 37%,rgba(0,0,0,0) 68%,rgba(0,0,0,0) 100%);
    background: -webkit-radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.2) 37%,rgba(0,0,0,0) 68%,rgba(0,0,0,0) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.2) 37%,rgba(0,0,0,0) 68%,rgba(0,0,0,0) 100%);
  }

  .flexbox #cspio-page{
    align-items: center;
    justify-content: center;
  }

  .cspio body{
    background: transparent;
  }

  .cspio body, .cspio body p, #cspio-description, #cspio-privacy-policy-txt{
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.50em;
    color:#ffffff;
  }

  #cspio-description, #cspio-logo, #cspio-socialprofiles, #cspio-privacy-policy-txt, #cspio-headline, #cspio-progressbar, #cspio-countdown, #cspio-thankyoumsg, #cspio-sharebuttons, #cspio-afterform {
    margin-bottom: 30px;
  }

  #cspio-privacy-policy-txt {
    display: inline-block;
    font-size: 11px;
}

  #cspio-content {
    display: none;
    max-width: 600px;
    background-color: #000000;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    background-color: transparent;
  }
  #cspio-content {
    width: 100%;
    padding: 30px;
    margin: auto;
    text-align: center;
  }

  #cspio-headline {
    font-family: 'Pacifico';
    font-weight: 400;
    font-size: 48px;
    color: #ffffff;
    line-height: 1.00em;
  }

  #cspio-headline {
    margin-top: 0;
    margin-bottom: 30px;
  }

</style>
